import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      hasUserId: "auth/hasUserId",
    }),
  },

  data() {
    return {
      activeIdleModal: false,
      timerId: null,
      active: false,
      timeInterval: 30000, // 30 sec
      events: ["mousemove", "keydown", "mousedown", "touchstart"],
    };
  },

  methods: {
    checkSessionExpired() {
      const currentDate = new Date();
      const millisecondsNow = currentDate.getTime();
      let getLastActiveTime = localStorage.getItem("lastActiveTime");

      if (this.isEmpty(getLastActiveTime)) {
        this.setActiveTime();
        getLastActiveTime = localStorage.getItem("lastActiveTime");
      }

      if (millisecondsNow > +getLastActiveTime + this.$idleTime) {
        if (this.activeIdleModal) {
          return false;
        }

        // this.$route.name !== "maintenance"
        if (this.hasUserId) {
          this.activeIdleModal = true;
        }
      }
    },

    setActiveTime() {
      const currentDate = new Date();
      this.active = false;

      localStorage.setItem("lastActiveTime", currentDate.getTime());
    },

    setActive() {
      if (this.active) {
        return true;
      }

      this.activeIdleModal = false;
      this.active = true;
    },

    startIdle() {
      if (!this.hasUserId) {
        return false;
      }

      this.events.forEach((evt) =>
        window.addEventListener(evt, this.setActive, false)
      );

      this.timerId = setInterval(() => {
        if (this.active) {
          this.setActiveTime();
        }

        this.checkSessionExpired();
      }, this.timeInterval);
    },
  },

  mounted() {
    this.startIdle();
  },
};
